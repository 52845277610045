import { Box, useTheme, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { getAttachmentURL } from "../../utils/attachments/attachment";
import { NormalAttachmentRenderer } from "./NormalAttachmentRenderer";
import { useWindowSize } from "react-use";

export enum VisualMediaType {
    Image,
    Video,
}

interface VisualMediaRendererProps {
    mediaType: VisualMediaType;
    attachment: any;
    updateRowHeight?: (scroll: boolean, newLoaded: boolean) => any;
    openImageViewer?: (attachmentPublicHash?: string) => any;
}

export const VisualMediaRenderer: React.FC<VisualMediaRendererProps> = ({
    attachment,
    updateRowHeight,
    mediaType,
    openImageViewer,
}) => {
    const [errored, setErrored] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const onError = () => {
        setErrored(true);
    };

    const usableWidth = useWindowSize().width * 0.75;
    const theme = useTheme();
    const midToLarge = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const smallToMid = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));

    const widthMultiplier = lessThanSmall
        ? 1
        : smallToMid
        ? 0.8
        : midToLarge
        ? 0.6
        : 0.3;

    const maxWidth = widthMultiplier * usableWidth;
    const maxHeight = 300;
    const ogHeight = maxWidth * (attachment.Height / attachment.Width);
    const useHeight = ogHeight > maxHeight ? maxHeight : ogHeight;

    return (
        <>
            {errored ? (
                <NormalAttachmentRenderer attachment={attachment} />
            ) : (
                <Box
                    sx={{
                        // maxHeight: "50rem",
                        // maxWidth: {
                        //     xs: "100%",
                        //     sm: "60%",
                        //     md: "40%",
                        //     lg: "30%",
                        //     xl: "25%",
                        // },
                        // position: "relative",
                        // marginBottom: -0.5,
                        height: `${useHeight}px`,
                        width: `${
                            attachment.Width * (attachment.Height / useHeight)
                        }`,
                    }}
                >
                    {mediaType === VisualMediaType.Image && (
                        <img
                            // style={{
                            //     maxHeight: "30rem",
                            //     maxWidth: "100%",
                            // }}
                            style={{
                                // width: "100%",
                                height: "100%",
                            }}
                            alt={attachment.Name}
                            src={getAttachmentURL(
                                attachment.PublicHash,
                                attachment.Name
                            ).toString()}
                            onError={onError}
                            onLoad={() => {
                                if (!loaded)
                                    updateRowHeight &&
                                        updateRowHeight(true, true);
                                setLoaded(true);
                            }}
                            onClick={() =>
                                openImageViewer &&
                                openImageViewer(attachment.PublicHash)
                            }
                        />
                    )}
                    {mediaType === VisualMediaType.Video && (
                        <video
                            controls
                            style={{
                                // maxHeight: "30rem",
                                // height: "auto",
                                // // width: "auto",
                                // maxWidth: "100%",
                                // width: "100%",
                                // width: "100%",
                                height: "100%",
                            }}
                            src={getAttachmentURL(
                                attachment.PublicHash,
                                attachment.Name
                            ).toString()}
                            onError={onError}
                            onLoad={() => {
                                if (!loaded)
                                    updateRowHeight &&
                                        updateRowHeight(true, true);
                                setLoaded(true);
                            }}
                        />
                    )}
                </Box>
            )}
        </>
    );
};
