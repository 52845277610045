import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiSwipeableDrawer from "@mui/material/SwipeableDrawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import HomeIcon from "@mui/icons-material/Home";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { drawerWidth } from "../../config";
import { TopAppBar } from "../TopAppBar/TopAppBar";
import FaceIcon from "@mui/icons-material/Face";
import { ItemList, ItemListItem } from "./ItemList";
import { CollectivesList } from "./CollectivesList";
import { Avatar, Typography } from "@mui/material";
import { selectPageTitle } from "../../reducers/utils-reducer";
import { useAppSelector } from "../../hooks";
import { VoiceHandler } from "../VoiceHandler/VoiceHandler";

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 0,
    // width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const SwipeableDrawer = styled(MuiSwipeableDrawer, {
    // shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

interface LeftDrawerProps {
    children: React.ReactNode;
    title?: string;
}

export const LeftDrawer: React.FC<LeftDrawerProps> = (props) => {
    const { children } = props;
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        document
            .querySelector("meta[name=theme-color]")
            ?.setAttribute("content", theme.palette.primary.main);
        document.body.style.background = theme.palette.primary.main;
    }, [theme]);

    const title = useAppSelector(selectPageTitle) || "TCS";

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const firstDividerItems: ItemListItem[] = [
        {
            key: "Home",
            icon: (
                <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                    <HomeIcon />
                </Avatar>
            ),
            label: "Home",
            redirect: "/welcome",
        },
        {
            key: "User Settings",
            icon: (
                <Avatar sx={{ bgcolor: theme.palette.secondary.main }}>
                    <FaceIcon />
                </Avatar>
            ),
            label: "User Settings",
        },
    ];

    return (
        <Box
            sx={{
                display: "flex",
                textAlign: "left",
            }}
        >
            <TopAppBar
                open={open}
                title={title}
                handleDrawerOpen={handleDrawerOpen}
            />

            <SwipeableDrawer
                variant="permanent"
                sx={{ display: "flex" }}
                open={open}
                onOpen={() => {
                    setOpen(true);
                    console.log("open");
                }}
                onClose={() => setOpen(false)}
            >
                <DrawerHeader>
                    <Typography
                        variant="h5"
                        sx={{
                            position: "absolute",
                            width: "100%",
                            textAlign: "center",
                        }}
                    >
                        TCS
                    </Typography>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? (
                            <ChevronRightIcon />
                        ) : (
                            <ChevronLeftIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <ItemList
                    open={open}
                    items={firstDividerItems}
                    close={() => setOpen(false)}
                />
                <Divider />
                <Box
                    sx={{
                        flex: 1,
                        overflowY: "scroll",
                        overflowX: "hidden",
                        scrollbarWidth: "none",
                    }}
                    className="no-scrollbar"
                >
                    <CollectivesList open={open} close={() => setOpen(false)} />
                </Box>
                <Divider />
                {/* <Box sx={{ mt: "auto" }}> */}
                <VoiceHandler open={open} />
                {/* </Box> */}
            </SwipeableDrawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                <Box
                    sx={{
                        position: "fixed",
                        left: {
                            xs: 0,
                            sm: `64px`,
                        },
                        width: {
                            xs: "100%",
                            sm: "calc(100% - 64px)",
                        },
                        top: theme.mixins.toolbar.minHeight,
                        height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
                        paddingBottom: `var(--padding-bottom)`,
                    }}
                >
                    {children}
                </Box>
            </Box>
        </Box>
    );
};
