import axios from "axios";
import useWebSocket, { ReadyState } from "react-use-websocket";
import useSWR from "swr";
import { WS_BASE } from "../../../../config";
import { swrFetcher } from "../../../../swr";

export const useVoiceWS = (channelPublicHash: string) => {
    const wsPath = new URL(
        `channels/channel/${channelPublicHash}/voice/join`,
        WS_BASE
    ).toString();

    const { sendMessage, lastMessage, readyState } = useWebSocket(wsPath, {
        onOpen: () => console.log("opened"),
    });

    if (readyState === ReadyState.OPEN) {
        sendMessage(axios.defaults.headers.common["Authorization"] as string);
    }

    return { sendMessage, lastMessage, readyState };
};
