import { API_BASE } from "../../../config";

export const getAttachmentURL = (
    attachmentPublicHash: string,
    name: string
) => {
    return new URL(
        `attachments/attachment/${attachmentPublicHash}/${name}`,
        API_BASE
    );
};

export const downloadAttachment = (
    attachmentPublicHash: string,
    name: string
) => {
    const a = document.createElement("a");
    a.href = getAttachmentURL(attachmentPublicHash, name).toString();
    a.download = name;
    console.log(a);
    a.click();
};
