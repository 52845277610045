import {
    Button,
    Container,
    Fab,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
import ChatIcon from "@mui/icons-material/Chat";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { useAppDispatch, useAppSelector } from "../hooks";
import {
    logOut,
    selectDisplayName,
    selectPublicHash,
} from "../reducers/user-self-reducer";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { JoinCollective } from "../components/JoinCollective/JoinCollective";
import { LeftDrawer } from "../components/LeftDrawer/LeftDrawer";
import { setPageTitle } from "../reducers/utils-reducer";
import { CreateCollective } from "../components/CreateCollective/CreateCollective";

export const WelcomePage: React.FC = () => {
    const displayName = useAppSelector(selectDisplayName);
    const publicHash = useAppSelector(selectPublicHash) || "";
    const [copied, setCopied] = useState(false);
    const [collectiveJoinOpen, setCollectiveJoinOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const copyPublicHash = () => {
        navigator.clipboard.writeText(publicHash);
        setCopied(true);
    };

    const handleLogOut = () => {
        dispatch(logOut());
        navigate("/");
    };

    const handleCollectiveJoinClose = () => {
        setCollectiveJoinOpen(false);
    };

    useEffect(() => {
        dispatch(setPageTitle("Home"));
    }, [dispatch]);

    const [createCollectiveOpen, setCreateCollectiveOpen] = useState(false);

    const handleCreateCollectiveClose = useCallback(() => {
        setCreateCollectiveOpen(false);
    }, [setCreateCollectiveOpen]);
    return (
        <>
            <LeftDrawer title="Home">
                <Container
                    sx={{
                        px: {
                            xs: "10%",
                        },
                        textAlign: "center",
                    }}
                    maxWidth="md"
                >
                    <Grid
                        sx={{
                            paddingTop: "30%",
                            height: "100%",
                            width: "100%",
                        }}
                        container
                        gap={3}
                    >
                        <Grid xs={12} sx={{ paddingBottom: "10%" }}>
                            <Typography variant="h4">Welcome Back, </Typography>
                            <Typography variant="h5">{displayName}</Typography>
                        </Grid>
                        <Grid xs={12} sx={{ paddingBottom: "10%" }}>
                            <Typography variant="body1">
                                Your public hash is
                            </Typography>
                            <TextField
                                variant="outlined"
                                size="small"
                                value={publicHash}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            onClick={() => copyPublicHash()}
                                        >
                                            {copied ? (
                                                <DoneIcon />
                                            ) : (
                                                <ContentCopyIcon />
                                            )}
                                        </IconButton>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid xs={12}>
                            <Fab
                                variant="extended"
                                color="primary"
                                sx={{ width: "90%" }}
                                onClick={() => setCollectiveJoinOpen(true)}
                            >
                                <ChatIcon sx={{ mr: 1 }} />
                                Join Collective
                            </Fab>
                        </Grid>
                        <Grid xs={12}>
                            <Typography variant="subtitle1">Or</Typography>
                        </Grid>
                        <Grid xs={12}>
                            <Fab
                                variant="extended"
                                color="secondary"
                                sx={{ width: "90%" }}
                                onClick={() => setCreateCollectiveOpen(true)}
                            >
                                <AddCircleOutlineIcon sx={{ mr: 1 }} />
                                Create Collective
                            </Fab>
                        </Grid>
                    </Grid>
                    <Button
                        sx={{
                            position: "absolute",
                            bottom: "1rem",
                            left: "50%",
                            transform: "translateX(-50%)",
                            marginBottom: `var(--padding-bottom)`,
                        }}
                        variant="outlined"
                        color="error"
                        onClick={handleLogOut}
                    >
                        Logout
                    </Button>

                    {collectiveJoinOpen && (
                        <JoinCollective
                            handleClose={handleCollectiveJoinClose}
                        />
                    )}

                    {createCollectiveOpen && (
                        <CreateCollective
                            handleClose={handleCreateCollectiveClose}
                        />
                    )}
                </Container>
            </LeftDrawer>
        </>
    );
};
