import axios from "axios";
import useSWR, { mutate } from "swr";
import { swrFetcher } from "../../../swr";

export const createCollective = async (name: string, description: string) => {
    const resp = await axios.post(`collectives/create`, {
        name,
        description,
    });
    mutate(`collectives/self`);
    return resp;
};
