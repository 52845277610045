import React, { useEffect } from "react";
import { Box, Typography, Avatar } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useVoiceWS } from "../../utils/channels/channel/voice";
import { configuration } from "./rtc-configs";
import { useAppSelector } from "../../hooks";
import { selectVoiceChannelPublicHash } from "../../reducers/voice-state-reducer";
import { useChannel } from "../../utils/channels/channel";
import { stringAvatar } from "../../utils/avatar";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { VoiceConnection } from "./VoiceConnection";

interface VoiceHandlerProps {
    open: boolean;
}

export const VoiceHandler: React.FC<VoiceHandlerProps> = ({ open }) => {
    const voiceChannelPublicHash = useAppSelector(selectVoiceChannelPublicHash);

    const { channel, isLoading, isError } = useChannel(
        voiceChannelPublicHash || ""
    );

    return (
        <Grid2 container direction="row" sx={{ p: 1.5 }}>
            <Grid2 xs={3}>
                {!isError && !isLoading ? (
                    <Avatar {...stringAvatar(channel.Name)} />
                ) : (
                    <Avatar>
                        <VolumeOffIcon />
                    </Avatar>
                )}
            </Grid2>
            {open && (
                <Grid2 xs={9} container>
                    <Grid2 xs={12}>
                        <Typography
                            variant="body1"
                            sx={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                        >
                            {isError || isLoading
                                ? "Voice Disconnected"
                                : channel.Name}
                        </Typography>
                    </Grid2>
                    <Grid2 xs={12}>
                        {/* <Typography variant="body1">Channel Name</Typography> */}
                    </Grid2>
                </Grid2>
            )}
            {voiceChannelPublicHash && (
                <VoiceConnection VoiceChannelHash={voiceChannelPublicHash} />
            )}
        </Grid2>
    );
};
