import axios from "axios";
import useSWR from "swr";
import { swrFetcher } from "../../../swr";

export const getSelfCollectives = async () => {
    const resp = await axios.get(`collectives/self`);
    return resp.data;
};

export const useSelfCollectives = () => {
    const { data, error } = useSWR(`collectives/self`, swrFetcher);
    return {
        collectives: data,
        isLoading: !error && !data,
        isError: error,
    };
};
