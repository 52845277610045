import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
interface UtilsState {
    PageTitle?: string;
}

const initialState: UtilsState = {};

export const utilsSlice = createSlice({
    name: "utils",
    initialState,
    reducers: {
        setPageTitle: (state, action: PayloadAction<string>) => {
            state.PageTitle = action.payload;
            document.title = `${action.payload} | TCS`;
        },
    },
});

export const { setPageTitle } = utilsSlice.actions;

export const selectPageTitle = (state: RootState) => state.utils.PageTitle;

export default utilsSlice.reducer;
