import axios from "axios";
import useWebSocket, { ReadyState } from "react-use-websocket";
import useSWR from "swr";
import { WS_BASE } from "../../../config";
import { swrFetcher } from "../../../swr";

export const useChannelMessages = (channelPublicHash: string) => {
    const { data, error } = useSWR(
        `channels/channel/${channelPublicHash}/channel-messages`,
        swrFetcher
    );
    return {
        channel: data,
        isLoading: !error && !data,
        isError: error,
    };
};

export const useChannel = (channelPublicHash: string) => {
    const { data, error } = useSWR(
        `channels/channel/${channelPublicHash}`,
        swrFetcher
    );
    return {
        channel: data,
        isLoading: !error && !data,
        isError: error,
    };
};

export const sendMessage = async (
    channelPublicHash: string,
    messageContent: string,
    attachments: File[]
) => {
    const form = new FormData();

    console.log(attachments);

    form.append("content", messageContent);
    attachments.forEach((attachment) => {
        form.append("attachments", attachment);
    });

    console.log(form);

    const resp = await axios.post(
        `channels/channel/${channelPublicHash}/message-create`,
        form,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    );
    // no longer needed because of websocket
    // mutate(`channels/channel/${channelPublicHash}/channel-messages`);
    return resp;
};

export const useLiveFeed = (channelPublicHash: string) => {
    const wsPath = new URL(
        `channels/channel/${channelPublicHash}/live-feed`,
        WS_BASE
    ).toString();

    const { sendMessage, lastMessage, readyState } = useWebSocket(wsPath, {
        onOpen: () => console.log("opened"),
    });

    if (readyState === ReadyState.OPEN) {
        sendMessage(axios.defaults.headers.common["Authorization"] as string);
    }

    return { sendMessage, lastMessage, readyState };
};
