import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@mui/material";
import React, { useState } from "react";

interface JoinCollectiveFindProps {
    open: boolean;
    handleClose: () => any;
    handleFind: (publicHash: string) => any;
}

export const JoinCollectiveFind: React.FC<JoinCollectiveFindProps> = (
    props
) => {
    const { open, handleClose, handleFind } = props;
    const [publicHash, setPublicHash] = useState("");
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Join A Collective</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To join a collective, please enter its public hash here.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Collective Public Hash"
                    fullWidth
                    variant="standard"
                    onChange={(e) => setPublicHash(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleFind(publicHash)}>Join</Button>
            </DialogActions>
        </Dialog>
    );
};
