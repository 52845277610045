import {
    Avatar,
    Container,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography,
    IconButton,
    Button,
    Hidden,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks";
import { setPageTitle } from "../reducers/utils-reducer";
import { stringAvatar } from "../utils/avatar";
import TagIcon from "@mui/icons-material/Tag";
import {
    setInfoLocalStore,
    useCollectiveChannels,
} from "../utils/collectives/collective";
import { CreateChannel } from "../components/CreateChannel/CreateChannel";
import AddIcon from "@mui/icons-material/Add";
import { deepPurple } from "@mui/material/colors";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import {
    connectToVoiceChannel,
    selectVoiceChannelPublicHash,
} from "../reducers/voice-state-reducer";

const CollectiveChannelsPage: React.FC = () => {
    const { CollectivePublicHash } = useParams();

    const { collective, member, isLoading, isError } = useCollectiveChannels(
        CollectivePublicHash || ""
    );

    const [createChannelOpen, setCreateChannelOpen] = useState(false);

    const voiceChannelPublicHash = useAppSelector(selectVoiceChannelPublicHash);

    useEffect(() => {
        setInfoLocalStore(CollectivePublicHash);
    }, [CollectivePublicHash]);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!collective) return;

        dispatch(setPageTitle(collective.Name));
    }, [collective, dispatch]);

    if (isLoading || isError) {
        return <></>;
    }

    console.log(collective);

    const channels = collective.Channels;

    const copyChannelHash = async () => {
        CollectivePublicHash &&
            (await navigator.clipboard.writeText(CollectivePublicHash));
    };

    const handleVoiceChannelJoin = (channelPublicHash: string) => {
        dispatch(connectToVoiceChannel(channelPublicHash));
    };

    return (
        <Container
            sx={{
                justifyContent: "center",
                display: "flex",
            }}
            maxWidth="md"
        >
            <List
                sx={{
                    width: "100%",
                    paddingY: "10%",
                    height: "100%",
                }}
            >
                {channels.map((channel: any, i: number) => (
                    <React.Fragment key={channel.PublicHash}>
                        <ListItem
                            alignItems="flex-start"
                            secondaryAction={
                                <Button
                                    aria-label="join voice"
                                    size="small"
                                    color="primary"
                                    variant={
                                        channel.PublicHash ===
                                        voiceChannelPublicHash
                                            ? "contained"
                                            : "outlined"
                                    }
                                    sx={{ width: "4.4rem" }}
                                    onClick={(e) =>
                                        handleVoiceChannelJoin(
                                            channel.PublicHash
                                        )
                                    }
                                    endIcon={<VolumeUpIcon />}
                                >
                                    {channel.PublicHash ===
                                    voiceChannelPublicHash
                                        ? "In"
                                        : "Join"}
                                </Button>
                            }
                        >
                            <ListItemButton
                                onClick={() => {
                                    navigate(
                                        `/channels/channel/${channel.PublicHash}`
                                    );
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar {...stringAvatar(channel.Name)} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                textOverflow: "hidden",
                                                paddingRight: -1,
                                                // lineBreak: "anywhere",
                                            }}
                                        >
                                            {channel.Name}
                                        </Typography>
                                    }
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                sx={{ display: "inline" }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {channel.Description}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                        {i !== channels.length - 1 && (
                            <Divider variant="inset" component="li" />
                        )}
                    </React.Fragment>
                ))}
                {member?.IsAdministrator && (
                    <ListItem>
                        <ListItemButton
                            onClick={() => setCreateChannelOpen(true)}
                        >
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: deepPurple[500] }}>
                                    <AddIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary="Create Channel"
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: "inline" }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            Create a new Channel
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                )}
                <ListItem>
                    <ListItemButton onClick={copyChannelHash}>
                        <ListItemAvatar>
                            <Avatar>
                                <TagIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Copy Collective Hash"
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: "inline" }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        Share with your friends so they can join
                                        the collective!
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItemButton>
                </ListItem>
            </List>
            {createChannelOpen && (
                <CreateChannel
                    collectiveHash={CollectivePublicHash || ""}
                    handleClose={() => setCreateChannelOpen(false)}
                />
            )}
        </Container>
    );
};

export default CollectiveChannelsPage;
