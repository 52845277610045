import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { REHYDRATE } from "redux-persist";
import axios from "axios";

interface UserSelfState {
    DisplayName?: string;
    AccessHash?: string;
    PublicHash?: string;
}

const initialState: UserSelfState = {};

export const userSelfSlice = createSlice({
    name: "user-self",
    initialState,
    reducers: {
        setDisplayName: (state, action: PayloadAction<string>) => {
            state.DisplayName = action.payload;
        },
        setAccessHash: (state, action: PayloadAction<string>) => {
            state.AccessHash = action.payload;
            axios.defaults.headers.common["Authorization"] = state.AccessHash;
        },
        setPublicHash: (state, action: PayloadAction<string>) => {
            if (state) state.PublicHash = action.payload;
        },
        logOut: (state) => {
            state.AccessHash = undefined;
            state.DisplayName = undefined;
            state.PublicHash = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(REHYDRATE, (state, payload) => {
            console.log("rehydrate", payload);
            axios.defaults.headers.common["Authorization"] = (
                payload as any
            ).payload?.userSelf?.AccessHash;
        });
    },
});

export const { setDisplayName, setAccessHash, setPublicHash, logOut } =
    userSelfSlice.actions;

export const selectDisplayName = (state: RootState) =>
    state.userSelf.DisplayName;
export const selectAccessHash = (state: RootState) =>
    state.userSelf?.AccessHash;
export const selectPublicHash = (state: RootState) =>
    state.userSelf?.PublicHash;

export default userSelfSlice.reducer;
