import { Box, Chip, Fab, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import SendIcon from "@mui/icons-material/Send";
import { sendMessage } from "../../utils/channels/channel";
import AddIcon from "@mui/icons-material/Add";

const RoundedTextField = styled(TextField)(({ theme }) => ({
    root: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderRadius: `50%`,
            },
        },
    },
}));

interface MessageEntererProps {
    channelPublicHash: string;
}

export const MessageEnterer: React.FC<MessageEntererProps> = ({
    channelPublicHash,
}) => {
    const [text, setText] = useState("");
    const [attachments, setAttachments] = useState<File[]>([]);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const textInputRef = useRef<HTMLInputElement | null>(null);

    const handleSendMessage = async () => {
        if (!canSend()) return;
        sendMessage(channelPublicHash, text, [...attachments]);
        setText("");
        setAttachments([]);
        console.log(textInputRef.current);
        textInputRef.current?.focus();
    };

    const chooseFiles = async () => {
        // console.log(inputRef.current);
        if (!inputRef.current) return;

        inputRef.current.click();

        inputRef.current.onchange = () => {
            if (!inputRef.current) return;
            const files = inputRef.current.files;
            if (!files) return;
            setAttachments((a) => [...a, ...files]);
        };
    };

    const handleRemoveAttachment = (attachment: File) => {
        setAttachments((as) => as.filter((a) => a !== attachment));
    };

    const canSend = () => text.length > 0 || attachments.length > 0;

    useEffect(() => {
        console.log(attachments);
    }, [attachments]);

    const theme = useTheme();

    return (
        <Box
            sx={{
                paddingBottom: 1,
                paddingTop: 0.5,
                width: "100%",
                flexDirection: "column",
                display: "flex",
                marginTop: "auto",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",

                    flex: 1,
                    paddingBottom: 1,
                    paddingX: 1,
                    // marginLeft: 0.5,
                    marginLeft: {
                        xs: 0.5,
                        sm: 2,
                    },
                    overFlow: "wrap",
                }}
            >
                {attachments.map((attachment) => (
                    <Chip
                        label={attachment.name}
                        size="medium"
                        onDelete={() => handleRemoveAttachment(attachment)}
                    />
                ))}
            </Box>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    background: theme.palette.background.paper,
                }}
            >
                <Fab
                    size="small"
                    color="secondary"
                    onClick={() => chooseFiles()}
                    sx={{
                        // marginX: 1,
                        transform: "scale(0.85)",
                        marginRight: -0.5,
                        marginLeft: {
                            xs: 1,
                            sm: 2.5,
                        },
                    }}
                >
                    <AddIcon />
                </Fab>
                <RoundedTextField
                    size="small"
                    inputRef={textInputRef}
                    variant="outlined"
                    multiline
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    maxRows={5}
                    fullWidth
                    onKeyDown={async (e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            e.stopPropagation();
                            await handleSendMessage();
                        }
                    }}
                    sx={{
                        flex: 1,
                        marginLeft: 1,
                    }}
                />

                <Fab
                    size="small"
                    color={canSend() ? "primary" : "default"}
                    sx={{
                        marginLeft: 1,
                        marginRight: {
                            xs: 1,
                            sm: 2.5,
                        },
                        transform: "scale(0.85)",
                    }}
                    onClick={handleSendMessage}
                >
                    <SendIcon />
                </Fab>
            </Box>
            <input
                type="file"
                multiple
                style={{ display: "none" }}
                ref={inputRef}
            />
        </Box>
    );
};
