import { Box } from "@mui/material";

import { NormalAttachmentRenderer } from "./NormalAttachmentRenderer";
import { Fragment, useState } from "react";
import { VisualMediaRenderer, VisualMediaType } from "./VisualMediaRenderer";
import Viewer from "react-viewer";
import { getAttachmentURL } from "../../utils/attachments/attachment";

interface RenderAttachmentsProps {
    attachments: any[];
    updateRowHeight?: (scroll: boolean, newLoaded: boolean) => any;
}

export const RenderAttachments: React.FC<RenderAttachmentsProps> = ({
    attachments,
    updateRowHeight,
}) => {
    const [imageViewerVisible, setImageViewerVisible] = useState(false);
    const [imageViewerActiveIndex, setImageViewerActiveIndex] = useState(0);

    if (!attachments || attachments.length < 1) {
        return <></>;
    }

    const images: { src: string; alt: string; attachmentPublicHash: string }[] =
        attachments
            .filter((attachment) => attachment.MimeType?.startsWith("image"))
            .map((image) => ({
                src: getAttachmentURL(image.PublicHash, image.Name).toString(),
                alt: image.Name,
                attachmentPublicHash: image.PublicHash,
            }));

    const openImageViewer = (attachmentPublicHash?: string) => {
        if (attachmentPublicHash) {
            const index = images.findIndex(
                (image) => image.attachmentPublicHash === attachmentPublicHash
            );
            index >= 0 && setImageViewerActiveIndex(index);
        }

        setImageViewerVisible(true);
        console.log("image viewer");

        <div className=""></div>;
    };

    return (
        <Box
            sx={{
                display: "flex",
                gap: "0.7rem",
                marginBottom: 0.5,
                flexDirection: "column",
            }}
        >
            {attachments.map((attachment) => (
                <Fragment key={attachment.PublicHash}>
                    {attachment.Width && attachment.Height ? (
                        attachment.MimeType?.startsWith("image") ? (
                            <VisualMediaRenderer
                                updateRowHeight={updateRowHeight}
                                attachment={attachment}
                                mediaType={VisualMediaType.Image}
                                openImageViewer={openImageViewer}
                            />
                        ) : attachment.MimeType?.startsWith("video") ? (
                            <VisualMediaRenderer
                                updateRowHeight={updateRowHeight}
                                attachment={attachment}
                                mediaType={VisualMediaType.Video}
                            />
                        ) : (
                            <NormalAttachmentRenderer attachment={attachment} />
                        )
                    ) : (
                        <NormalAttachmentRenderer attachment={attachment} />
                    )}
                </Fragment>
            ))}

            <Viewer
                visible={imageViewerVisible}
                zIndex={10000}
                activeIndex={imageViewerActiveIndex}
                onChange={(image, index) => {
                    setImageViewerActiveIndex(index);
                }}
                noToolbar={true}
                onMaskClick={() => setImageViewerVisible(false)}
                onClose={() => {
                    setImageViewerVisible(false);
                }}
                noClose={true}
                images={images}
            />
        </Box>
    );
};
