import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@mui/material";
import React, { useState } from "react";
import { CreateCollectiveInfo } from "./CreateCollective";

interface CreateCollectiveInformationProps {
    open: boolean;
    handleClose: () => any;
    handleInformation: (information: CreateCollectiveInfo) => any;
}

export const CreateCollectiveInformation: React.FC<
    CreateCollectiveInformationProps
> = (props) => {
    const { open, handleClose, handleInformation } = props;
    const [collectiveName, setCollectiveName] = useState("");
    const [collectiveDescription, setCollectiveDescription] = useState("");
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Create A Collective</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Collective Name (2 char min, 25 max)"
                    fullWidth
                    placeholder="Greatest Collective"
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    onChange={(e) => setCollectiveName(e.target.value)}
                />
                <TextField
                    autoFocus
                    multiline
                    InputLabelProps={{ shrink: true }}
                    placeholder="I am the greatest collective of all!"
                    rows={4}
                    margin="dense"
                    label="Description"
                    fullWidth
                    variant="standard"
                    onChange={(e) => setCollectiveDescription(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Cancel</Button>
                <Button
                    onClick={() => {
                        if (!collectiveName || collectiveName.length < 2) {
                            return;
                        }
                        handleInformation({
                            name: collectiveName,
                            description: collectiveDescription,
                        });
                    }}
                >
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};
