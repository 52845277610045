import React, { useState } from "react";
import {
    getCollective,
    joinCollective,
} from "../../utils/collectives/collective";
import { JoinCollectiveFind } from "./JoinCollectiveFind";
import { JoinCollectiveJoin } from "./JoinCollectiveJoin";

interface JoinCollectiveProps {
    handleClose?: () => any;
}

export const JoinCollective: React.FC<JoinCollectiveProps> = (props) => {
    const { handleClose } = props;
    const [findOpen, setFindOpen] = useState(true);

    const [joinOpen, setJoinOpen] = useState(false);
    const [collectiveName, setCollectiveName] = useState("");
    const [collectiveDescription, setCollectiveDescription] = useState("");
    const [collectivePublicHash, setCollectivePublicHash] = useState("");

    const handleFind = async (collectivePublicHash: string) => {
        console.log(`Finding collective: ${collectivePublicHash}`);
        try {
            const collective = await getCollective(collectivePublicHash);
            if (!collective) handleClose && handleClose();
            console.log(collective);
            setCollectiveName(collective.Name);
            setCollectiveDescription(collective.Description);
            setCollectivePublicHash(collective.PublicHash);
            setFindOpen(false);
            setJoinOpen(true);
        } catch (e) {
            console.error(e);
            handleClose && handleClose();
        }
    };

    const handleJoin = async () => {
        console.log(`Joining ${collectivePublicHash}`);
        console.log(await joinCollective(collectivePublicHash));
        handleClose && handleClose();
    };

    return (
        <>
            <JoinCollectiveFind
                open={findOpen}
                handleClose={handleClose || (() => {})}
                handleFind={handleFind}
            />
            <JoinCollectiveJoin
                open={joinOpen}
                handleClose={handleClose || (() => {})}
                collectiveName={collectiveName}
                collectiveDescription={collectiveDescription}
                collectivePublicHash={collectivePublicHash}
                handleJoin={handleJoin}
            />
        </>
    );
};
