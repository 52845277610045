import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import "./App.css";
import { isMobile } from "react-device-detect";
import MobileWebHomePage from "./pages/MobileWebHomePage";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import axios from "axios";
import { API_BASE } from "./config";
import { PersistGate } from "redux-persist/integration/react";
import { AppRouter } from "./router";
import { Analytics } from "@vercel/analytics/react";

axios.defaults.baseURL = API_BASE;
axios.defaults.headers.post["Content-Type"] = "application/json";

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        background: {
            // paper: "#e8eaf6",
        },
    },
    typography: {
        caption: {
            fontSize: 11,
        },
        subtitle1: {
            fontSize: 15,
        },
    },
});

const isInStandaloneMode = () =>
    window.matchMedia("(display-mode: standalone)").matches ||
    (window.navigator as any).standalone ||
    document.referrer.includes("android-app://");

const isDevMode =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development";

function App() {
    return (
        <div
            className="App"
            style={{
                background: theme.palette.background.default,
            }}
        >
            <CssBaseline />
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ThemeProvider theme={theme}>
                        {isMobile && !isInStandaloneMode() && !isDevMode ? (
                            <MobileWebHomePage />
                        ) : (
                            <AppRouter />
                        )}
                    </ThemeProvider>
                </PersistGate>
            </Provider>
            <Analytics />
        </div>
    );
}

export default App;
