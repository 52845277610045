import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import React from "react";

interface CreateChannelCreateProps {
    open: boolean;
    handleClose: () => any;
    handleCreate: () => any;
    channelName: string;
    channelDescription?: string;
}

export const CreateChannelCreate: React.FC<CreateChannelCreateProps> = (
    props
) => {
    const { open, handleClose, handleCreate, channelName, channelDescription } =
        props;
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{channelName}</DialogTitle>
            <DialogContent>
                <DialogContentText>{channelDescription}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
            <DialogActions>
                <Button onClick={handleCreate}>Confirm Create</Button>
            </DialogActions>
        </Dialog>
    );
};
