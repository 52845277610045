import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import React from "react";

interface JoinCollectiveJoinProps {
    open: boolean;
    handleClose: () => any;
    handleJoin: () => any;
    collectiveName: string;
    collectiveDescription?: string;
    collectivePublicHash: string;
}

export const JoinCollectiveJoin: React.FC<JoinCollectiveJoinProps> = (
    props
) => {
    const {
        open,
        handleClose,
        handleJoin,
        collectiveName,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        collectivePublicHash,
        collectiveDescription,
    } = props;
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{collectiveName}</DialogTitle>
            <DialogContent>
                <DialogContentText>{collectiveDescription}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleJoin}>Confirm Join</Button>
            </DialogActions>
        </Dialog>
    );
};
