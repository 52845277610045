import React, { useEffect } from "react";
import { configuration } from "./rtc-configs";
import { useVoiceWS } from "../../utils/channels/channel/voice";

interface VoiceManager {
    [CounterPartyPublicHash: string]: {
        CounterPartyPublicHash: string;
        ConnectionID: string;
        PeerConnection: RTCPeerConnection;
    };
}

interface ReceivedMessage {
    event: string;
    CounterParty: string;
    ConnectionID: string;
    data?: any;
}

const voiceManager: VoiceManager = {};
const audio = document.createElement("audio");

//@ts-ignore
window.audio = audio;

interface VoiceConnectionProps {
    VoiceChannelHash: string;
}

export const VoiceConnection: React.FC<VoiceConnectionProps> = ({
    VoiceChannelHash,
}) => {
    const { sendMessage, lastMessage, readyState } =
        useVoiceWS(VoiceChannelHash);

    useEffect(() => {
        (async () => {
            if (readyState !== 1) return;
            if (!lastMessage) return;
            console.log(voiceManager);
            console.log(lastMessage);
            const message: ReceivedMessage = JSON.parse(lastMessage.data);

            const closeConnection = () => {
                if (message.CounterParty in voiceManager) {
                    if (
                        message.ConnectionID ===
                        voiceManager[message.CounterParty].ConnectionID
                    ) {
                        voiceManager[
                            message.CounterParty
                        ].PeerConnection?.close();
                        delete voiceManager[message.CounterParty];
                    }
                }
            };

            if (message.event === "close-connection") {
                closeConnection();
            } else if (message.event === "request-offer") {
                closeConnection();

                const newPeerConnection = new RTCPeerConnection(configuration);

                voiceManager[message.CounterParty] = {
                    CounterPartyPublicHash: message.CounterParty,
                    ConnectionID: message.ConnectionID,
                    PeerConnection: newPeerConnection,
                };

                try {
                    const stream = await navigator.mediaDevices.getUserMedia({
                        audio: true,
                        video: false,
                    });

                    newPeerConnection.addTrack(stream.getAudioTracks()[0]);
                } catch (e) {
                    document.body.onclick = async () => {
                        const stream =
                            await navigator.mediaDevices.getUserMedia({
                                audio: true,
                                video: false,
                            });

                        newPeerConnection.addTrack(stream.getAudioTracks()[0]);

                        document.body.onclick = null;
                    };
                }
                newPeerConnection.addEventListener("track", async (e) => {
                    console.log(e);
                    const track = e.track;
                    const stream = new MediaStream();
                    stream.addTrack(track);
                    console.log(stream);
                    audio.srcObject = stream;
                    await audio.play();
                });

                const connInfo = voiceManager[message.CounterParty];

                const offer = await newPeerConnection.createOffer({
                    // offerToReceiveAudio: true,
                });
                await newPeerConnection.setLocalDescription(offer);
                console.log(offer);

                newPeerConnection.addEventListener("icecandidate", (event) => {
                    console.log("ice created", event);
                    if (event.candidate) {
                        sendMessage(
                            JSON.stringify({
                                event: "icecandidate-created",
                                CounterParty: message.CounterParty,
                                ConnectionID: message.ConnectionID,
                                data: {
                                    icecandidate: event.candidate,
                                },
                            })
                        );
                    }
                });

                sendMessage(
                    JSON.stringify({
                        event: "offer-created",
                        CounterParty: connInfo.CounterPartyPublicHash,
                        ConnectionID: connInfo.ConnectionID,
                        data: {
                            offer,
                        },
                    })
                );

                newPeerConnection.addEventListener(
                    "connectionstatechange",
                    (event) => {
                        console.log(event);
                    }
                );
            } else if (message.event === "offer-delivery") {
                const newPeerConnection = new RTCPeerConnection(configuration);
                voiceManager[message.CounterParty] = {
                    CounterPartyPublicHash: message.CounterParty,
                    ConnectionID: message.ConnectionID,
                    PeerConnection: newPeerConnection,
                };

                try {
                    const stream = await navigator.mediaDevices.getUserMedia({
                        audio: true,
                        video: false,
                    });

                    newPeerConnection.addTrack(stream.getAudioTracks()[0]);
                } catch (e) {
                    document.body.onclick = async () => {
                        const stream =
                            await navigator.mediaDevices.getUserMedia({
                                audio: true,
                                video: false,
                            });

                        newPeerConnection.addTrack(stream.getAudioTracks()[0]);

                        document.body.onclick = null;
                    };
                }

                newPeerConnection.addEventListener("track", async (e) => {
                    console.log(e);
                    const track = e.track;
                    const stream = new MediaStream();
                    stream.addTrack(track);
                    console.log(stream);
                    audio.srcObject = stream;
                    await audio.play();
                });

                const offer = new RTCSessionDescription(message.data.offer);

                console.log("offer", offer);
                newPeerConnection.setRemoteDescription(offer);
                const answer = await newPeerConnection.createAnswer({});
                await newPeerConnection.setLocalDescription(answer);

                newPeerConnection.addEventListener("icecandidate", (event) => {
                    console.log("ice created", event);
                    if (event.candidate) {
                        sendMessage(
                            JSON.stringify({
                                event: "icecandidate-created",
                                CounterParty: message.CounterParty,
                                ConnectionID: message.ConnectionID,
                                data: {
                                    icecandidate: event.candidate,
                                },
                            })
                        );
                    }
                });

                sendMessage(
                    JSON.stringify({
                        event: "answer-created",
                        CounterParty: message.CounterParty,
                        ConnectionID: message.ConnectionID,
                        data: {
                            answer,
                        },
                    })
                );
                newPeerConnection.addEventListener(
                    "connectionstatechange",
                    (event) => {
                        console.log(event);
                    }
                );
                console.log(`offer deliver end`, voiceManager);
            } else if (message.event === "answer-delivery") {
                const connInfo = voiceManager[message.CounterParty];
                if (connInfo.ConnectionID !== message.ConnectionID) {
                    return;
                }

                const answer = new RTCSessionDescription(message.data.answer);

                console.log("answer", answer);
                try {
                    await connInfo.PeerConnection.setRemoteDescription(answer);
                } catch (e) {
                    console.log(e);
                }
            } else if (message.event === "icecandidate-delivery") {
                console.log("icedelivery");
                const connInfo = voiceManager[message.CounterParty];
                if (connInfo.ConnectionID !== message.ConnectionID) {
                    return;
                }
                const icecandidate = message.data?.icecandidate;
                try {
                    await connInfo.PeerConnection.addIceCandidate(icecandidate);
                    console.log(icecandidate);
                } catch (e) {
                    console.log(e);
                }
            }
        })();
    }, [readyState, lastMessage, sendMessage]);

    return <></>;
};
