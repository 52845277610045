import React, { useState } from "react";
import { CreateChannelInformation } from "./CreateChannelInformation";
import { CreateChannelCreate } from "./CreateChannelCreate";
import { createChannel } from "../../utils/channels/create";

interface CreateChannelProps {
    handleClose?: () => any;
    collectiveHash: string;
}

export interface CreateChannelInfo {
    name: string;
    description: string;
}

export const CreateChannel: React.FC<CreateChannelProps> = (props) => {
    const { handleClose: ogHandleClose, collectiveHash } = props;
    const handleClose = () => {
        ogHandleClose && ogHandleClose();
        setCreateOpen(false);
        setInformationOpen(false);
    };
    const [informationOpen, setInformationOpen] = useState(true);

    const [createOpen, setCreateOpen] = useState(false);
    const [channelName, setchannelName] = useState("");
    const [channelDescription, setchannelDescription] = useState("");

    const handleInformation = (info: CreateChannelInfo) => {
        try {
            setchannelName(info.name);
            setchannelDescription(info.description);
            setInformationOpen(false);
            setCreateOpen(true);
        } catch (e) {
            console.error(e);
            handleClose && handleClose();
        }
    };

    const handleCreate = async () => {
        console.log(`Creating ${channelName}`);
        console.log(
            await createChannel(channelName, channelDescription, collectiveHash)
        );
        handleClose && handleClose();
    };

    return (
        <>
            <CreateChannelInformation
                open={informationOpen}
                handleClose={handleClose || (() => {})}
                handleInformation={handleInformation}
            />
            <CreateChannelCreate
                open={createOpen}
                handleClose={handleClose || (() => {})}
                channelName={channelName}
                channelDescription={channelDescription}
                handleCreate={handleCreate}
            />
        </>
    );
};
