export const configuration = {
    iceServers: [
        {
            urls: ["turn:turn.tong.icu"],
            username: "timmyturner",
            credential: "turmmytiner",
        },
        { urls: "stun:stun.l.google.com:19302" },
    ],
    iceCandidatePoolSize: 10,
};
