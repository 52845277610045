import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@mui/material";
import React, { useState } from "react";
import { CreateChannelInfo } from "./CreateChannel";

interface CreateChannelInformationProps {
    open: boolean;
    handleClose: () => any;
    handleInformation: (information: CreateChannelInfo) => any;
}

export const CreateChannelInformation: React.FC<
    CreateChannelInformationProps
> = (props) => {
    const { open, handleClose, handleInformation } = props;
    const [channelName, setchannelName] = useState("");
    const [channelDescription, setchannelDescription] = useState("");
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Create A channel</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="channel Name (2 char min, 25 max)"
                    fullWidth
                    placeholder="Greatest channel"
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    onChange={(e) => setchannelName(e.target.value)}
                />
                <TextField
                    autoFocus
                    multiline
                    InputLabelProps={{ shrink: true }}
                    placeholder="I am the greatest channel of all!"
                    rows={4}
                    margin="dense"
                    label="Description"
                    fullWidth
                    variant="standard"
                    onChange={(e) => setchannelDescription(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Cancel</Button>
                <Button
                    onClick={() => {
                        if (!channelName || channelName.length < 2) {
                            return;
                        }
                        handleInformation({
                            name: channelName,
                            description: channelDescription,
                        });
                    }}
                >
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};
