import axios from "axios";
import useSWR, { mutate } from "swr";
import { swrFetcher } from "../../../swr";

export const createChannel = async (
    name: string,
    description: string,
    collectiveHash: string
) => {
    const resp = await axios.post(`channels/create`, {
        name,
        description,
        collectiveHash,
    });
    mutate(`collectives/collective/${collectiveHash}/collective-channels`);
    return resp;
};
