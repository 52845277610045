import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { MessageEnterer } from "../components/MessageEnterer/MessageEnterer";
// import { MessagesDisplay } from "../components/MessagesDisplay/MessagesDisplay";
import { MessagesDisplay2 } from "../components/MessagesDisplay/MessagesDisplay2";
import { setInfoLocalStore } from "../utils/collectives/collective";

const ChannelPage: React.FC = () => {
    const { ChannelPublicHash } = useParams();
    useEffect(() => {
        setInfoLocalStore(undefined, ChannelPublicHash);
    }, [ChannelPublicHash]);
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <MessagesDisplay2 channelPublicHash={ChannelPublicHash || ""} />
            <MessageEnterer channelPublicHash={ChannelPublicHash || ""} />
        </Box>
    );
};

export default ChannelPage;
