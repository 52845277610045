import {
    combineReducers,
    configureStore,
    getDefaultMiddleware,
} from "@reduxjs/toolkit";
import userSelfReducer from "./reducers/user-self-reducer";
import storage from "redux-persist/lib/storage";
import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
    persistStore,
    persistReducer,
} from "redux-persist";
import utilsReducer from "./reducers/utils-reducer";
import voiceStateReducer from "./reducers/voice-state-reducer";

const persistConfig = {
    key: "root",
    storage,
    blackList: ["voice-state"],
};

const rootReducer = combineReducers({
    userSelf: userSelfReducer,
    utils: utilsReducer,
    voiceState: voiceStateReducer,
});

const persistR = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistR,
    devTools: true,
    middleware: [
        ...getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
    ],
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
