import { Container, Grid, Typography } from "@mui/material";
import { isIOS } from "react-device-detect";
import PWAPrompt from "react-ios-pwa-prompt";

const MobileWebHomePage: React.FC = () => {
    return (
        <Container
            sx={{
                px: {
                    xs: "10%",
                },
            }}
            maxWidth="md"
        >
            <Grid
                sx={{ paddingTop: "30%", height: "100%", width: "100%" }}
                container
                gap={3}
            >
                <Grid xs={12} sx={{ paddingBottom: "10%" }}>
                    <Typography variant="h4">TCS</Typography>
                    <Typography variant="subtitle2">
                        A Chat Messager Created by Tong.
                    </Typography>
                </Grid>
                <Grid xs={12}>
                    <Typography variant="subtitle2">
                        TCS is designed to be used as an installed app.
                    </Typography>
                    <Typography variant="subtitle2">
                        Please follow the prompt to add to homescreen / install
                        as an app.
                    </Typography>
                    <Typography variant="subtitle2">
                        Use safari on ios and chrome on android if your current
                        browser does not support the required functions.
                    </Typography>
                    <Typography variant="subtitle2">
                        If no prompt is shown on android, click on the three
                        dots and click on Install app.
                    </Typography>
                </Grid>
            </Grid>
            {isIOS && (
                <PWAPrompt
                    delay={500}
                    debug={true}
                    copyBody="TCS is designed to be ultilized as an app. Please only use it after adding to homescreen."
                    permanentlyHideOnDismiss={false}
                    copyClosePrompt=""
                />
            )}
        </Container>
    );
};

export default MobileWebHomePage;
