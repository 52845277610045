import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import React from "react";

interface CreateCollectiveCreateProps {
    open: boolean;
    handleClose: () => any;
    handleCreate: () => any;
    collectiveName: string;
    collectiveDescription?: string;
}

export const CreateCollectiveCreate: React.FC<CreateCollectiveCreateProps> = (
    props
) => {
    const {
        open,
        handleClose,
        handleCreate,
        collectiveName,
        collectiveDescription,
    } = props;
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{collectiveName}</DialogTitle>
            <DialogContent>
                <DialogContentText>{collectiveDescription}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
            <DialogActions>
                <Button onClick={handleCreate}>Confirm Create</Button>
            </DialogActions>
        </Dialog>
    );
};
