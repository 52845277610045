import axios from "axios";
import useSWR, { mutate } from "swr";
import { swrFetcher } from "../../../swr";

export const getCollective = async (publicHash: string) => {
    const resp = await axios.get(`collectives/collective/${publicHash}`);
    return resp.data;
};

export const joinCollective = async (publicHash: string) => {
    const resp = await axios.post(`collectives/collective/${publicHash}/join`);
    mutate(`collectives/self`);
    return resp;
};

export const useCollectiveChannels = (collectivePublicHash: string) => {
    const { data, error } = useSWR(
        `collectives/collective/${collectivePublicHash}/collective-channels`,
        swrFetcher
    );
    console.log(data);
    return {
        collective: data?.collective,
        member: data?.member,
        isLoading: !error && !data,
        isError: error,
    };
};

export const setInfoLocalStore = (
    collectiveHash?: string,
    channelHash?: string
) => {
    window.localStorage.setItem("collectiveHash", collectiveHash || "");
    window.localStorage.setItem("channelHash", channelHash || "");
};
