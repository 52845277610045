import {
    Avatar,
    Box,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { stringAvatar } from "../../utils/avatar";
import { useChannelMessages, useLiveFeed } from "../../utils/channels/channel";
import { RenderAttachments } from "./RenderAttachments";
import AutoSizer from "react-virtualized-auto-sizer";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { selectPublicHash } from "../../reducers/user-self-reducer";
import { useAppSelector } from "../../hooks";

interface MessageDisplay2Props {
    channelPublicHash: string;
}

export const MessagesDisplay2: React.FC<MessageDisplay2Props> = ({
    channelPublicHash,
}) => {
    const { channel, isLoading, isError } =
        useChannelMessages(channelPublicHash);

    const selfPublicHash = useAppSelector(selectPublicHash);

    const { lastMessage } = useLiveFeed(channelPublicHash);

    const virtuosoRef = useRef<VirtuosoHandle>(null);

    const [messages, setMessages] = useState<any[]>(channel?.Messages || []);
    const [atBottom, setAtBottom] = useState<boolean>(true);
    const [prevHeight, setPrevHeight] = useState(0);

    const handleScroll: React.UIEventHandler<HTMLDivElement> = (e) => {
        const div = e.target as HTMLDivElement;
        if (div.scrollTop === div.scrollHeight - div.offsetHeight) {
            setAtBottom(true);
        } else {
            setAtBottom(false);
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const scroll = (depth?: number) => {
        console.log("scroll", depth);
        const d = depth || 1;
        requestAnimationFrame(() => {
            setTimeout(() => {
                virtuosoRef.current?.scrollToIndex({
                    index: 99999999,
                    align: "end",
                });
                if (d > 1) scroll(d - 1);
            }, 200);
        });
    };

    useEffect(() => {
        if (!lastMessage) return;
        const message = JSON.parse(lastMessage.data);
        if (message.event === "message-create") {
            const newMessage = message.data.message;
            console.log(
                newMessage,
                atBottom,
                newMessage.Author.User.PublicHash,
                selfPublicHash
            );

            setMessages((ms) => {
                if (newMessage.PublicHash === ms[ms.length - 1]?.PublicHash)
                    return ms;
                if (
                    atBottom ||
                    newMessage.Author.User.PublicHash === selfPublicHash
                ) {
                    scroll();
                }
                return [...ms, newMessage];
            });
        }
    }, [lastMessage, atBottom, scroll, selfPublicHash]);

    useEffect(() => {
        setMessages(() => channel?.Messages);
    }, [channel]);

    useEffect(() => {
        scroll(2);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading || isError || !channel) {
        return <Box sx={{ flex: "1" }}></Box>;
    }

    function Row(index: number) {
        const message = messages[index];
        return (
            <ListItem
                alignItems="flex-start"
                style={{
                    height: "",
                    textOverflow: "wrap",
                    overflowWrap: "break-word",
                    display: "flex",
                    flexDirection: "row",
                }}
                key={index}
            >
                <ListItemAvatar>
                    <Avatar
                        {...stringAvatar(message.Author.User.DisplayName)}
                    />
                </ListItemAvatar>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        // wordWrap: "break-word",
                    }}
                >
                    <ListItemText
                        primary={
                            <>
                                <Typography variant="subtitle1">
                                    {message.Author.User.DisplayName}
                                </Typography>
                            </>
                        }
                        secondary={
                            <React.Fragment>
                                {message.Content.split("\n").map(
                                    (line: string) => {
                                        return (
                                            <Typography
                                                sx={{
                                                    overFlow: "hidden",
                                                    overflowWrap: "anywhere",
                                                    maxWidth: "100%",
                                                }}
                                                // component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {line}
                                            </Typography>
                                        );
                                    }
                                )}
                            </React.Fragment>
                        }
                    />
                    <RenderAttachments attachments={message.Attachments} />
                    <Typography variant="caption">
                        {moment(message.Created).calendar()}
                    </Typography>
                </Box>
            </ListItem>
        );
    }

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flex: "1",
                paddingX: {
                    xs: -1.5,
                    sm: 1,
                },
                flexDirection: "column",
            }}
        >
            <AutoSizer
                onResize={(e) => {
                    if (prevHeight !== 0) {
                        virtuosoRef.current?.scrollBy({
                            top: prevHeight - e.height,
                        });
                    }
                    setPrevHeight(e.height);
                }}
            >
                {({ height, width }: { height: number; width: number }) => (
                    <Virtuoso
                        ref={virtuosoRef}
                        overscan={{
                            main: window.innerHeight,
                            reverse: window.innerHeight,
                        }}
                        initialTopMostItemIndex={messages?.length - 1 || 0}
                        style={{ height, width }}
                        totalCount={messages?.length}
                        // @ts-ignore
                        onScroll={handleScroll}
                        itemContent={(index) => Row(index)}
                    />
                )}
            </AutoSizer>
        </Box>
    );
};
