import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { REHYDRATE } from "redux-persist";
import axios from "axios";

interface VoiceState {
    VoiceChannelPublicHash?: string;
    InVoiceChannel: boolean;
    Muted: boolean;
    Deafened: boolean;
}

const initialState: VoiceState = {
    Muted: false,
    Deafened: false,
    InVoiceChannel: false,
};

export const voiceStateSlice = createSlice({
    name: "voice-state",
    initialState,
    reducers: {
        connectToVoiceChannel: (state, action: PayloadAction<string>) => {
            state.VoiceChannelPublicHash = action.payload;
            state.InVoiceChannel = true;
        },
        disconnectFromVoiceChannel: (state) => {
            state.VoiceChannelPublicHash = undefined;
            state.InVoiceChannel = false;
        },
        setMuted: (state, action: PayloadAction<boolean>) => {
            state.Muted = action.payload;
        },
        setDeafened: (state, action: PayloadAction<boolean>) => {
            state.Deafened = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(REHYDRATE, (state, payload) => {
            console.log("rehydrate", payload);
        });
    },
});

export const { connectToVoiceChannel, disconnectFromVoiceChannel } =
    voiceStateSlice.actions;
export const selectVoiceChannelPublicHash = (state: RootState) =>
    state.voiceState.VoiceChannelPublicHash;
export const selectInVoiceChannel = (state: RootState) =>
    state.voiceState.InVoiceChannel;
export const selectMuted = (state: RootState) => state.voiceState.Muted;
export const selectDeafened = (state: RootState) => state.voiceState.Deafened;

export default voiceStateSlice.reducer;
