import React, { useCallback } from "react";
import { useSelfCollectives } from "../../utils/collectives/self";
import ErrorIcon from "@mui/icons-material/Error";
import { ItemList, ItemListItem } from "./ItemList";
import { Avatar } from "@mui/material";
import { stringAvatar } from "../../utils/avatar";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { CreateCollective } from "../CreateCollective/CreateCollective";
import { useState, useEffect } from "react";

interface CollectivesListProps {
    open: boolean;
    close: () => any;
}

export const CollectivesList: React.FC<CollectivesListProps> = ({
    open,
    close,
}) => {
    const { collectives, isLoading, isError } = useSelfCollectives();
    const [createCollectiveOpen, setCreateCollectiveOpen] = useState(false);

    useEffect(() => {
        console.log(createCollectiveOpen);
    }, [createCollectiveOpen]);

    const handleCreateCollectiveClose = useCallback(() => {
        console.log("close create");
        setCreateCollectiveOpen(false);
    }, [setCreateCollectiveOpen]);

    if (isLoading) return <></>;
    const collectivesItemList: ItemListItem[] = isError
        ? [
              {
                  key: "CollectivesError",
                  label: "Unable to load collectives",
                  icon: <ErrorIcon />,
              },
          ]
        : collectives.map((collective: any) => ({
              key: collective.PublicHash,
              label: collective.Name,
              icon: <Avatar {...stringAvatar(collective.Name)} />,
              onClick: () => {
                  console.log(
                      `navigating to ${collective.PublicHash} - ${collective.Name}`
                  );
              },
              redirect: `collectives/collective/${collective.PublicHash}/channels`,
          }));

    const createCollectiveItem = {
        key: "create-collective",
        label: "Create Collective",
        icon: (
            <Avatar>
                <AddCircleIcon />
            </Avatar>
        ),
        onClick: () => setCreateCollectiveOpen(true),
    };

    collectivesItemList.push(createCollectiveItem);

    return (
        <>
            <ItemList close={close} open={open} items={collectivesItemList} />
            {createCollectiveOpen && (
                <CreateCollective handleClose={handleCreateCollectiveClose} />
            )}
        </>
    );
};
