import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LeftDrawer } from "./components/LeftDrawer/LeftDrawer";
import ChannelPage from "./pages/ChannelPage";
import CollectiveChannelsPage from "./pages/CollectiveChannelsPage";
import LoginPage from "./pages/LoginPage";
import { WelcomePage } from "./pages/WelcomePage";

export const AppRouter = () => (
    <Router>
        <Routes>
            <Route path="/" element={<LoginPage />} />

            <Route path="*" element={<WrappedRouter />} />
        </Routes>
    </Router>
);

const WrappedRouter = () => (
    <LeftDrawer>
        <Routes>
            <Route path="/welcome" element={<WelcomePage />} />
            <Route
                path="/collectives/collective/:CollectivePublicHash/channels"
                element={<CollectiveChannelsPage />}
            />
            <Route
                path="/channels/channel/:ChannelPublicHash"
                element={<ChannelPage />}
            />
        </Routes>
    </LeftDrawer>
);
