import {
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export interface ItemListItem {
    key: string;
    label: string;
    icon: React.ReactElement<any>;
    otherContent?: React.ReactElement<any>;
    onClick?: () => any;
    redirect?: string;
}

interface ItemListProps {
    open: boolean;
    items: ItemListItem[];
    close: () => any;
}

export const ItemList: React.FC<ItemListProps> = ({ open, items, close }) => {
    const navigate = useNavigate();
    return (
        <List>
            {items.map((item) => (
                <ListItem
                    key={item.key}
                    disablePadding
                    sx={{ display: "block" }}
                >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                        }}
                        onClick={() => {
                            item.onClick && item.onClick();
                            close();
                            item.redirect && navigate(item.redirect);
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                            }}
                        >
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText
                            primary={item.label}
                            sx={{ opacity: open ? 1 : 0 }}
                        />
                        {item.otherContent}
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    );
};
