import React, { useEffect, useState } from "react";
import { sha3_256 } from "js-sha3";

import { Button, Container, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
    selectAccessHash,
    selectPublicHash,
    setAccessHash,
    setDisplayName,
    setPublicHash,
} from "../reducers/user-self-reducer";
import { useNavigate } from "react-router-dom";
import { getSelf } from "../utils/users/self";
import { setPageTitle } from "../reducers/utils-reducer";

const LoginPage: React.FC = () => {
    const dispatch = useAppDispatch();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [hash, setHash] = useState("");
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const navigate = useNavigate();
    const accessHash = useAppSelector(selectAccessHash);
    const publicHash = useAppSelector(selectPublicHash);

    document
        .querySelector("meta[name=theme-color]")
        ?.setAttribute("content", "#FFF");

    document.body.style.background = "#FFF";

    useEffect(() => {
        if (!!accessHash && !!publicHash) {
            navigate("/welcome");
        }
    }, [accessHash, publicHash, navigate]);

    useEffect(() => {
        dispatch(setPageTitle("Login"));
    }, [dispatch]);

    const logIn = async () => {
        console.log("logging in");
        setIsLoggingIn(true);
        dispatch(setAccessHash(hash));
        const self = await getSelf();
        dispatch(setPublicHash(self.PublicHash));
        dispatch(setDisplayName(self.DisplayName));
        navigate("/welcome");
    };

    useEffect(() => {
        if (!username || !password) {
            setHash("");
            return;
        }
        const combined = JSON.stringify({
            username: username,
            password: password,
        });
        setHash(sha3_256(combined));
    }, [username, password]);

    return (
        <Container
            sx={{
                px: {
                    xs: "10%",
                },
                paddingBottom: `var(--padding-bottom)`,
            }}
            maxWidth="md"
        >
            <Grid
                sx={{ paddingTop: "30%", height: "100%", width: "100%" }}
                container
                gap={3}
            >
                <Grid xs={12} sx={{ paddingBottom: "10%" }}>
                    <Typography variant="h4">TCS</Typography>
                    <Typography variant="subtitle2">
                        A Chat Messager Created by Tong
                    </Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} xs={12}>
                    <Typography variant="subtitle2">Username</Typography>
                    <TextField
                        variant="outlined"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid sx={{ textAlign: "left" }} xs={12}>
                    <Typography variant="subtitle2">Password</Typography>
                    <TextField
                        variant="outlined"
                        placeholder="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyUp={(e) => e.key === "Enter" && logIn()}
                        fullWidth
                    />
                </Grid>
                <Grid
                    sx={{ textAlign: "left", overflowWrap: "anywhere" }}
                    xs={12}
                >
                    <Typography variant="body2">{hash}</Typography>
                </Grid>
                <Grid xs={12} sx={{ paddingTop: "0.5rem" }}>
                    <Button
                        disableElevation
                        disabled={!hash}
                        variant="contained"
                        sx={{ borderRadius: 8 }}
                        onClick={() => logIn()}
                    >
                        {isLoggingIn ? "Logging in" : "Login"}
                    </Button>
                </Grid>
                <Grid xs={12}>
                    <Typography variant="subtitle2">
                        Registration is not required, simply log in.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

export default LoginPage;
