import { Paper, Box, Typography, IconButton } from "@mui/material";
import prettyBytes from "pretty-bytes";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadAttachment } from "../../utils/attachments/attachment";

interface NormalAttachmentRendererProps {
    attachment: any;
}

export const NormalAttachmentRenderer: React.FC<
    NormalAttachmentRendererProps
> = ({ attachment }) => {
    const handleDownload = (attachment: any) => {
        const { PublicHash, Name } = attachment;
        downloadAttachment(PublicHash, Name);
    };

    return (
        <Paper
            variant="outlined"
            key={attachment.PublicHash}
            sx={{
                display: "inline-flex",
                gap: "1rem",
                padding: 1,
                alignItems: "center",
                minWidth: 0,
                maxWidth: {
                    xs: "100%",
                    sm: "60%",
                    md: "40%",
                    lg: "30%",
                    xl: "25%",
                },
                height: "5rem",
            }}
        >
            <InsertDriveFileIcon fontSize="large" />
            <Box>
                <Typography
                    variant="body2"
                    sx={{
                        // overflowX: "hidden",
                        // wordBreak: "never",
                        // whiteSpace: "nowrap",
                        overFlowWrap: "anywhere",
                        wordBreak: "break-all",
                    }}
                >
                    {attachment.Name}
                </Typography>

                <Typography variant="caption">
                    {prettyBytes(attachment.Size)}
                </Typography>
            </Box>
            <IconButton
                onClick={() => handleDownload(attachment)}
                sx={{ marginLeft: "auto" }}
            >
                <DownloadIcon fontSize="large" />
            </IconButton>
        </Paper>
    );
};
