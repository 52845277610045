import React, { useState } from "react";
import { createCollective } from "../../utils/collectives/create";
import { CreateCollectiveInformation } from "./CreateCollectiveInformation";
import { CreateCollectiveCreate } from "./CreateCollectiveCreate";

interface CreateCollectiveProps {
    handleClose?: () => any;
}

export interface CreateCollectiveInfo {
    name: string;
    description: string;
}

export const CreateCollective: React.FC<CreateCollectiveProps> = (props) => {
    const { handleClose: ogHandleClose } = props;
    const handleClose = () => {
        ogHandleClose && ogHandleClose();
        setCreateOpen(false);
        setInformationOpen(false);
    };
    const [informationOpen, setInformationOpen] = useState(true);

    const [createOpen, setCreateOpen] = useState(false);
    const [collectiveName, setCollectiveName] = useState("");
    const [collectiveDescription, setCollectiveDescription] = useState("");
    const [collectivePublicHash, setCollectivePublicHash] = useState("");

    const handleInformation = (info: CreateCollectiveInfo) => {
        try {
            setCollectiveName(info.name);
            setCollectiveDescription(info.description);
            setInformationOpen(false);
            setCreateOpen(true);
        } catch (e) {
            console.error(e);
            handleClose && handleClose();
        }
    };

    const handleCreate = async () => {
        console.log(`Creating ${collectiveName}`);
        console.log(
            await createCollective(collectiveName, collectiveDescription)
        );
        handleClose && handleClose();
    };

    return (
        <>
            <CreateCollectiveInformation
                open={informationOpen}
                handleClose={handleClose || (() => {})}
                handleInformation={handleInformation}
            />
            <CreateCollectiveCreate
                open={createOpen}
                handleClose={handleClose || (() => {})}
                collectiveName={collectiveName}
                collectiveDescription={collectiveDescription}
                handleCreate={handleCreate}
            />
        </>
    );
};
